/* src/HorizontalGauge.css */
.horizontal-gauge {
  position: relative;
  width: 100%;
  height: 120px;
  /* background-color: #ddd; */
  border-radius: 10px;
  overflow: hidden;
}

.gauge-fill {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
}

.pointer {
  position: absolute;
  bottom: 0;
  width: 2px;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid black;
  border-top: 0;
}

/* .arrow-up {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(180deg);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #000;
} */

.scale {
  position: relative;
  height: 100%;
  padding-left: 20px; /* Add padding on the left for the first value */
  padding-right: 20px; /* Add padding on the right for the last value */
}

.mark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 2px;
  height: 48px; /* Increase the height of the scale lines (4 times bigger than before) */
  background-color: #000;
}

.value-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}

.mark-label {
  position: absolute;
  top: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  color: #000;
}

.mark.show-label .mark-label {
  display: block;
}

.mark-label {
  display: none;
}

/* Style for the gauge line */
.gauge-line {
  position: absolute;
  top: 50%;
  left: 0;
  height: 2px; /* Adjust the height of the gauge line */
  width: 100%;
  background-color: #000; /* Set color of the gauge line */
  transform: translateY(-50%);
}

.tooltip {
  position: absolute;
  top: -30px; /* Adjust tooltip position */
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  display: none; /* Initially hide the tooltip */
}
