.sidebar_main {
  background-color: white !important;
  height: 100% !important;
  /* min-height: 100vh !important; */
  overflow: scroll !important;
  padding: 15px !important;
}
.sidebar_main::-webkit-scrollbar {
  display: none !important;
}

/* .sidebar_main_collapsed {
  width: 80px !important;
} */

.sidebar_tab_active,
.sidebar_tab {
  background-color: white !important;
  color: #606060 !important;
  height: max-content !important;
  padding: 12px 15px !important;
  border-radius: 100px !important;
  display: flex !important;
  justify-content: start !important;
  align-items: center !important;
  margin-bottom: 12px !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  cursor: pointer !important;
  transition: 0.3s ease-in-out !important;
}

.disabled_sidebar_tab {
  background-color: white !important;
  color: #c2c2c2 !important;
  height: max-content !important;
  padding: 12px 15px !important;
  border-radius: 100px !important;
  display: flex !important;
  justify-content: start !important;
  align-items: center !important;
  margin-bottom: 12px !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  cursor: pointer !important;
  transition: 0.3s ease-in-out !important;
}

.contact_us {
  /* border-top: solid gray 1px; */
  background-color: white !important;
  color: #606060 !important;
  height: max-content !important;
  padding: 12px 15px !important;
  /* border-radius: 100px !important; */
  display: flex !important;
  justify-content: start !important;
  align-items: center !important;
  margin-bottom: 12px !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  cursor: pointer !important;
  transition: 0.3s ease-in-out !important;
}

.knowledge_base {
  /* border-top: solid gray 1px; */
  background-color: white !important;
  color: #606060 !important;
  height: max-content !important;
  padding: 12px 15px !important;
  /* border-radius: 100px !important; */
  display: flex !important;
  justify-content: start !important;
  align-items: center !important;
  margin-bottom: 12px !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  cursor: pointer !important;
  transition: 0.3s ease-in-out !important;
}

.sidebar_tab_active {
  background-color: #201b5b !important;
  color: white !important;
  transition: 0.3s ease-in-out !important;
}
.sidebar_tab:hover {
  background-color: #201b5b !important;
  color: white !important;
  transition: 0.3s ease-in-out !important;
}

.disabled_sidebar_tab:hover {
  background-color: #665ec4 !important;
  color: #c2c2c2 !important;
  transition: 0.3s ease-in-out !important;
}

.mobile_logo {
  height: 50px;
  width: 330px;
}

.sidebar_ThomsonLogo {
  height: 100px;
  width: 220px;
  /* margin-top: 2vh; */

  min-height: 100px !important;
  min-width: 220px !important;
}

.naics_logo {
  height: 60px;
  width: 60px;
}
