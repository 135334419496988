.Simulation_heading {
  color: #121212 !important;
  font-size: 30px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
}
.Simulation_record_balance {
  color: #414141 !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
}

.Simulation_record {
  color: #414141 !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
}

.Simulation_record2 {
  display: flex;
  color: #414141 !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
}

.Simulation_record3 {
  color: #282a89 !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  padding-left: 5px;
}

.cashFlowStatus {
  color: gray !important;
  font-size: 25px !important;
  font-style: normal !important;
  font-weight: 650 !important;
  line-height: normal !important;
}

.difference {
  display: flex;
  align-items: center;
  color: gray !important;
  font-size: 25px !important;
  font-style: normal !important;
  font-weight: 650 !important;
  line-height: normal !important;
  text-align: center;
}

.line_of_credit_inputs {
  width: 90px !important;
  height: 31px !important;
  /* text-align: center !important; */
  background-color: white !important;
  border: none !important;
  border-color: rgb(158, 155, 155) !important;
  outline: none !important;
  padding-left: 7px;
}

.line_of_credit_inputs:focus {
  border: none !important;
  outline: none !important;
}

.avg_payroll_input_fmf {
  width: 60px !important;
  height: 31px !important;
  border-radius: 5px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  margin-left: 10px !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  text-align: center !important;
  background-color: white !important;
}

.DotIcon {
  width: 30px;
  height: 30px;
}
