.companydetail_inputs {
  height: 56px !important;
  border: 1px solid #b6b6b6 !important;
  border-radius: 100px !important;
  outline: none !important;
  font-size: 16px !important;
  padding: 0px 20px !important;
}
.companydetail_inputs::placeholder {
  color: rgb(158, 155, 155) !important;
}

::-webkit-scrollbar {
  width: 12px; /* Set the width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* Set the color of the thumb (the draggable part) */
}

.test-class {
  /* padding-right: 15; */
  min-height: 5000px !important;
  background-color: black !important;
}

.contact_us_description {
  height: 300px !important;
  border: 1px solid #b6b6b6 !important;
  border-radius: 15px !important;
  outline: none !important;
  font-size: 16px !important;
  padding: 20px !important;
  width: 95% !important;
  overflow-y: scroll !important;
  scrollbar-width: thin !important; /* Set the width of the scrollbar */
  scrollbar-color: #888 transparent !important;
}

.contact_us_description::placeholder {
  color: rgb(158, 155, 155) !important;
}

.text-area-input {
  height: 300px !important;
  border: 1px solid #b6b6b6 !important;
  border-radius: 15px !important;
  outline: none !important;
  font-size: 16px !important;
  padding: 20px !important;
  width: 95% !important;
  overflow-y: scroll !important;
  scrollbar-width: thin !important; /* Set the width of the scrollbar */
  scrollbar-color: #888 transparent !important;
}

.companydetail_logo_input_box {
  height: 56px !important;
  border: 1px solid #b6b6b6 !important;
  border-radius: 100px !important;
  font-size: 16px !important;
  padding: 10px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: start !important;
}
.Choose_File_btn {
  color: #00bdff !important;
  border-radius: 35px !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  background: rgba(0, 189, 255, 0.05) !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  padding: 8px 10px !important;
  cursor: pointer !important;
}

.disabled_Choose_File_btn {
  color: white !important;
  background-color: #afb0b2 !important;
  border-radius: 35px !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  /* background: rgba(0, 189, 255, 0.05) !important; */
  text-transform: capitalize !important;
  font-size: 16px !important;
  padding: 8px 10px !important;
  cursor: pointer !important;
}
.back_btn {
  font-size: 16px !important;
  color: #201b5b !important;
  background-color: rgba(32, 27, 91, 0.1) !important;
  border-radius: 100px !important;
  text-transform: capitalize !important;
  width: 98px !important;
  height: 48px !important;
  margin-right: 20px !important;
}
.save_btn {
  font-size: 16px !important;
  color: white !important;
  background-color: #201b5b !important;
  border-radius: 100px !important;
  text-transform: capitalize !important;
  width: 98px !important;
  height: 48px !important;
}

.disabled_save_btn {
  font-size: 16px !important;
  background-color: #828285 !important;
  color: #c2c2c2 !important;
  border-radius: 100px !important;
  text-transform: capitalize !important;
  width: 98px !important;
  height: 48px !important;
}
.add_member_btn {
  font-size: 16px !important;
  color: white !important;
  background-color: #201b5b !important;
  border-radius: 100px !important;
  text-transform: capitalize !important;
  width: 144px;
  height: 40px;
}

.disabled_add_member_btn {
  font-size: 16px !important;
  background-color: #828285 !important;
  color: #c2c2c2 !important;
  border-radius: 100px !important;
  text-transform: capitalize !important;
  width: 144px;
  height: 40px;
}

.disabled_add_client_btn {
  font-size: 16px !important;
  background-color: #828285 !important;
  color: #c2c2c2 !important;
  border-radius: 100px !important;
  text-transform: capitalize !important;
  width: 144px;
  height: 40px;
}

.add_client_btn {
  font-size: 16px !important;
  color: white !important;
  background-color: #201b5b !important;
  border-radius: 100px !important;
  text-transform: capitalize !important;
  width: 144px;
  height: 40px;
}
