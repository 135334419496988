.home_table_heading_row {
  /* background-color: #f2f2f5 !important; */
  padding-left: 16px !important;
  padding-right: 16px !important;
  padding-bottom: 10px !important;
  /* border-radius: 100px !important; */
  color: #606060 !important;
  border-bottom: 1px solid #e7e7e7 !important;
}
.home_table_data_row {
  background-color: white !important;
  padding: 20px 16px !important;
  color: #606060 !important;
  border-bottom: 1px solid #e7e7e7 !important;
  max-height: 80px !important;
}

.comp_name {
  font-size: 16;
  color: black;
}

.eye {
  font-size: 25px !important;
  padding-left: 8px !important;
  cursor: pointer !important;
}

.blur_comp_name {
  filter: blur(5px) !important;
}

.checkbox {
  width: 24px !important;
  height: 24px !important;
  border-radius: 5px !important;
}

.changeInValNeg {
  font-size: 19px;
  color: red;
}
.changeInValPos {
  font-size: 19px;
  color: #00bdff;
}

.financial_data_select {
  color: gray !important;
  border-radius: 20px !important;
  min-width: 170px !important;
  height: 45px !important;
  background-color: #e1e1e1 !important;
  border: none !important;
  outline: none !important;
}

.financial_doctor_select_date_type {
  color: gray !important;
  border-radius: 20px !important;
  min-width: 130px !important;
  height: 45px !important;
  background-color: #e1e1e1 !important;
  border: none !important;
  outline: none !important;
}

.home_table_container {
  height: 450px !important;
}
@media screen and (max-width: 1536px) {
  .home_table_container {
    height: 235px !important;
  }
}

.Home_Action_button {
  background-color: #e9f2f0;
  padding: 10px;
  border-radius: 50%;
}

.Gradient_Home_Page {
  background-color: rgb(226, 222, 241) !important;
  background-color: linear-gradient(
    32deg,
    rgba(226, 222, 241, 1) 35%,
    rgba(226, 238, 235, 1) 56%
  ) !important;
}
.financial_data_autoselect{
  color: gray !important;
  border-radius: 20px !important;
  min-width: 170px !important;
  height: 53px !important;
  background-color: #e1e1e1 !important;
  border: none !important;
  outline: none !important;
}