.cashMining-box {
  background-color: #ffffff;
  margin-top: 0.1% !important;
  padding: 3%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 2px 5px -3px;
  /* min-height: 89vh; */
  display: flex !important;
  flex-direction: column !important;
  /* justify-content: space-evenly;    */
}
.login-head-text {
  margin-top: 10%;
  font-size: 50px;
  margin-bottom: 0px !important;
  color: white;
}
.login-head-des {
  margin-top: 0%;
  font-size: 27px;
  margin-bottom: 10% !important;
  color: white;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  /* width: 10px !important;
  height: 100px !important; */
}

.fmfTitle {
  margin: 0 0 15px 657px;
  font-weight: 500;
}

.cashMining-box-table {
  background-color: #ffffff;
  min-height: 100vh !important;
  margin-top: 0.1% !important;
  padding: 3%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 2px 5px -3px;
}
.nav-btn {
  background-color: #673ab7 !important;
  color: white !important;
  border-radius: 5px 5px 0px 0px;
}

.main_bg {
  background-color: #03111e;
  padding-top: 30px;
}

.maindiv {
  width: 100vw;
  height: auto;
  display: flex;
  justify-content: center;
}
.text-2div {
  width: 100vw;
  height: auto;
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.text-1 {
  width: 80vw;
  height: auto;
  font-size: 62px;
  font-weight: 800;
  color: white;
  margin: 50px;
  text-align: center;
}
.text-2 {
  width: 80vw;
  font-size: 20px;
  font-weight: 500;
  color: white;
  line-height: 30px;
  /* margin: 50px; */
}

.maindiv2 {
  display: flex;
  justify-content: center;
  padding-top: 70px;
  padding-bottom: 70px;
  gap: 60px;
}

.package {
  background-color: white;
  width: 350px;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 25px;
}

.subHead {
  color: #00bdff;
  font-weight: 800;
  font-size: 40px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.subHead2 {
  color: black;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  height: auto;
  width: 352px;
}

.pack_btns {
  width: 150px;
  padding: 10px;
  background-color: #00c4f4;
  font-weight: 800;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
}

.pack_btns:hover {
  width: 150px;
  padding: 10px;
  background-color: #201b5b;
  font-weight: 800;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  color: white;
}

.desc_div {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 40px;
}

.login-bg {
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 20px;
}
.package-box {
  box-shadow: 0 4px 30px rgba(255, 255, 255, 0.404) !important;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 20px;
  background-color: #f8f5f5 !important;
  width: 30%;
  padding: 5%;
}
.main_div3 {
  background-color: white;
  backdrop-filter: blur(5px);
  border-radius: 20px;
}
.login-header {
  display: flex;
  align-items: center;
}

.trans_typo1 {
  /* line-height: 62px !important; */
  font-size: 40px !important;
  font-weight: 700 !important;
  color: #03111e !important;
}
.trans_typo2 {
  /* line-height: 62px !important; */
  font-size: 40px !important;
  font-weight: 700 !important;
  color: #03111e !important;
  text-align: center;
}
.download-title {
  font-size: 30px !important;
  font-weight: 700 !important;
  color: #03111e !important;
}

.centerDiv {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.rectangleID {
  width: 165px;
  height: 30px;
  background-color: #2f5597;
  color: white;
  padding: 20px;
}

.rectangleAR {
  width: 165px;
  height: 30px;
  background-color: #203864;
  color: white;
  padding: 20px;
}

.rectangleAP {
  width: 165px;
  height: 30px;
  background-color: #203864;
  color: white;
  padding: 20px;
}

.rectangleFG {
  width: 165px;
  height: 30px;
  background-color: #7a0000;
  color: white;
  padding: 20px;
}

.fmf_subheading {
  /* line-height: 20px !important; */
  font-size: 20px !important;
  font-weight: 400 !important;
  color: #03111e !important;
  margin-top: 20px !important;
}

.inventInput {
  width: 36px !important;
  max-width: 60px !important;
  height: 25px !important;
  border-radius: 10px;
  margin: 0px 10px;
  border: 1px gray solid;
  padding-left: 18px;
}

.width-full .MuiInputBase-root.MuiOutlinedInput-root {
  width: 400px !important;
}

.main_typo3 {
  /* line-height: 20px !important; */
  font-size: 20px !important;
  font-weight: 600 !important;
  color: #03111e !important;
  /* margin-top: 20px !important; */
  align: "left";
}

.add-member-btn {
  margin-top: 30px !important;
  background-color: #1976d2 !important;
  text-transform: none !important;
  color: white !important;
  font-family: arial !important;
  width: 150px !important;
}

.big_font {
  font-size: 2rem !important;
}

.disabled_add_tv {
  background-color: #828285 !important;
  color: #c2c2c2 !important;
  width: 100px !important;
  height: 48px !important;
  border-radius: 100px !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
}

.add_tv {
  background-color: #201b5b !important;
  color: white !important;
  width: 100px !important;
  height: 48px !important;
  border-radius: 100px !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
  /* margin-top: 30px !important; */
}

.save-btn-swe {
  background-color: #201b5b !important;
  color: white !important;
  width: 189px !important;
  height: 48px !important;
  border-radius: 100px !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
  margin-top: 30px !important;
}

.disabled-save-btn-swe {
  background-color: #828285 !important;
  color: #c2c2c2 !important;
  width: 189px !important;
  height: 48px !important;
  border-radius: 100px !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
  margin-top: 30px !important;
}

.login-btn {
  background-color: #201b5b !important;
  color: white !important;
  width: 189px !important;
  height: 48px !important;
  border-radius: 100px !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
  margin-top: 30px !important;
}

.got_it_button {
  background-color: #1976d2 !important;
  color: white !important;
  width: 189px !important;
  height: 48px !important;
  border-radius: 100px !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
  margin-top: 10vh !important;
}

.share_modal_button {
  background-color: #201b5b !important;
  color: white !important;
  width: 120px !important;
  height: 48px !important;
  border-radius: 100px !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
  margin-top: 30px !important;
}

.disabled-simple_val {
  background-color: #828285 !important;
  color: #c2c2c2 !important;
  width: 189px !important;
  height: 48px !important;
  border-radius: 100px !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
}

.simple_val {
  background-color: #201b5b !important;
  color: white !important;
  width: 189px !important;
  height: 48px !important;
  border-radius: 100px !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
}

.logout_sub_page {
  background-color: #130e47;
  color: #b9b4ff;
  float: right;
  padding: 20px 20px 0 0;
  text-decoration: underline;
  cursor: pointer;
}

.save-pdf-btn {
  margin-top: 30px !important;
  background-color: #1976d2 !important;
  text-transform: none !important;
  color: white !important;
  font-family: arial !important;
  width: 130px !important;
}

.red {
  color: red;
}
.coupon-input {
  font-family: arial !important;
  color: #808080 !important;
  background-color: transparent;
  width: 94% !important;
  height: 38px;
  padding-left: 5%;
  border-radius: 6px !important;
  border: 1px solid #808080;
}
.date_picker_width {
  width: 95% !important;
}
.customize-input1 {
  font-family: arial !important;
  color: #03111e !important;
  background-color: transparent;
  width: 95% !important;
  height: 50px;
  margin-top: 2%;
  padding-left: 5%;
  border-radius: 20px !important;
  border: 1px solid;
}
::placeholder {
  color: rgb(59, 54, 54);
  opacity: 1;
}
input:focus-visible {
  outline: 1px solid !important;
}

.sideBar-items {
  border-bottom: 1px solid gray;
  height: 6vh;
  width: 100%;
  cursor: pointer;
  margin: 0px !important;
  display: flex;
  align-items: center !important;
  padding: 5% 5px;
  font-weight: 600;
}
.sideBar-items:hover {
  background-color: #efefef;
}
.footer-bg {
  background-color: white !important;
  padding: 1% 4% !important;
  display: flex !important;
  align-items: center !important;
  margin-top: 0.1% !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 2px 5px 3px !important;
}
.footer-text {
  font-family: "Times New Roman", Times, serif !important;
  margin: 0px !important;
}
.sideBar-daed-links {
  border-bottom: 1px solid gray;
  height: 6vh;
  color: gray !important;
  width: 100%;
  margin: 0px !important;
  display: flex;
  align-items: center !important;
  padding: 5% 5px;
  font-weight: 600;
}
/* moving shape */
.shape1 {
  position: absolute;
  top: 260px;
  left: -30px;
  animation: move1 12s infinite;
}
@keyframes move1 {
  0% {
    left: -30px;
    top: 260px;
  }
  25% {
    left: 20px;
    top: 260px;
  }
  50% {
    left: -30px;
    top: 260px;
  }
  75% {
    left: 20px;
    top: 260px;
  }
  100% {
    left: -30px;
    top: 260px;
  }
}

.shape2 {
  position: absolute;
  top: 120px;
  right: 50px;
  /* animation: move2 9s infinite ; */
}

.shape3 {
  position: absolute;
  bottom: 100px;
  right: 10px;
  animation: move3 7s infinite;
}
@keyframes move3 {
  0% {
    right: 10px;
    bottom: 100px;
  }
  25% {
    right: 10px;
    bottom: 140px;
  }
  50% {
    right: 10px;
    bottom: 100px;
  }
  75% {
    right: 10px;
    bottom: 140px;
  }
  100% {
    right: 10px;
    bottom: 100px;
  }
}

.bg-img {
  background-image: url("../images//banner_bg.jpg");
  /* opacity:.5 ; */
  height: 600px;
  background-repeat: no-repeat;
  background-size: cover;
}
.main-title1 {
  color: white;
  margin-top: 10%;
  text-align: center;
  font-size: 72px;
  margin-bottom: 0px !important;
}
.main-title2 {
  margin-top: 0px !important;

  color: #00c4f4;
  text-align: center;
  font-size: 72px;
}
.main-title3 {
  color: white;
  text-align: center;
  /* width: 40%; */
  margin: 0 13%;
  font-size: 25px;
  /* background-color: rgba(0, 0, 0, 0.479); */
}
.custom-nav-btn {
  color: white !important;
  font-size: 18px !important;
}
.custom-btn {
  border: 2px solid #3196eb !important;
  border-radius: 24px !important;
  color: white !important;
  padding: 2% !important;
  font-size: 18px !important;
  width: 169px !important;
}
.custom-btn:hover {
  background-color: #3196eb !important;
}
.about-title {
  color: #ffffff;
  position: absolute;
  background-color: #00c4f4;
  padding: 1%;
  font-size: 48px;
  border-radius: 22px;
  top: -80px;
}
.about-title2 {
  color: white;
  font-size: 40px;
}
.about-des {
  color: white;
  font-size: 19px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  line-height: 30px;
}
.blog-head {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 2.5rem;
  text-transform: capitalize;
  font-weight: 700;
  color: #03111e;
}
.blog-title {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1.75rem;
  text-transform: capitalize;
  font-weight: 700;
  margin-top: 0px;
  color: #03111e;
}
.blog-des {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: #03111e;
}
.book-btn {
  background-color: #03111e !important;
  color: white !important;
  padding: 2% 5% !important;
  border-radius: 24px !important;
}
.book-btn:hover {
  transform: scale(1.025);
  transition: transform 0.6s;
  box-shadow: 0 5px 10px 0 transparent !important;
}
.testimonials-bg {
  background-image: url("../images/wave.svg");
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 2%;
  padding-top: 2%;
}
.testimonials-head {
  font-weight: 900;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
  font-size: 67px;
  color: white;
  text-align: center;
  margin-bottom: 6%;
  margin-top: 1%;
}
.card-box {
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  margin-bottom: 2% !important;
  padding: 2% !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}

.card-box:hover {
  transform: scale(1.025);
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.4);
  z-index: 3;
  border-color: #f3f0f0 !important;
  transition: transform 0.6s;
}
.card-title {
  font-weight: 600;
  text-align: center;
  font-size: 18px;
  font-family: arial !important;
}

.css-1lwj5bw-MuiPaper-root-MuiTableContainer-root {
  /* min-height: 100% !important; */
  max-height: 100% !important;
}

.card-des {
  margin: 0px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}
.card-img {
  height: 80px;
  width: 80px;
  border-radius: 50%;
}
.counter-bg {
  background-image: url("../images/curve.svg");
  background-repeat: no-repeat;
  background-size: cover;
  /* height: 300px; */
  /* margin-top: 2%; */
  /* padding-top: 2%; */
}
.counter-div {
  padding: 4% 3%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.counter-img {
  height: 120px;
}
.counter-title1 {
  color: white;
  margin: 0px;
  font-size: 27px;
  font-weight: 500;
  text-align: center;
}
.counter-title2 {
  font-size: 50px;
  font-weight: 900;
  margin: 0px !important;
  color: white;
  text-align: center;
}

.reset-btn {
  margin-top: 16px !important;
  font-size: 20px;
  text-decoration: none;
  color: white !important;
  background-color: #0c0f7c !important;
  padding: 1% 5%;
}

.notes_save_btn {
  font-size: 20px;
  text-decoration: none;
  color: white !important;
  background-color: #00c4f4 !important;
  padding: 1% 5% !important;
  padding: 5px 8px !important;
  border-radius: 5px !important;
  width: 100px !important;
  height: 30px !important;
  float: right !important;
  margin: 10px !important;
}

/* .css-15v22id-MuiAccordionDetails-root {
  padding: 0 !important;
} */

.notes_edit_btn {
  /* font-size: 20px; */
  /* text-decoration: none; */
  /* color: white !important; */
  /* background-color: #3e3e9b !important; */
  /* padding: 1% 5% !important;
  /* border-radius: 5px !important; */
  /* width: 100px !important; */
  /* height: 30px !important; */
  /* padding: 5px 8px !important; */
  padding-bottom: 13px;
  padding-top: 10px;
  float: right !important;
  /* margin: 10px !important; */
}

.anchor-btn {
  font-size: 15px !important;
  text-decoration: none;
  color: white !important;
  background-color: "#00C696";
  padding: 1% 5%;
  width: 110px !important;
  height: auto !important;
  font-weight: 550 !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  text-transform: none !important;
}

.disabled-anchor-btn {
  font-size: 20px;
  text-decoration: none;
  color: white !important;
  background-color: rgb(158, 155, 155) !important;
  padding: 1% 5%;
}

.download-title {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 40px;
  font-weight: 700;
  margin-top: 0px;
  color: #03111e;
}
.info-box {
  padding: 5%;
  background-color: #fbf9f9;
  color: white;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 20px;
}
.about-banner {
  display: flex;
  align-items: center;
  background-image: url("../images/banner_bg2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 5% 5% 8% 5%;
}
.about-area1 {
  display: flex;
  align-items: center;
  padding: 5%;
}
.scrollTop {
  border-top: 1px solid #1f262f;
  position: relative;
}
.scrollTop-btn {
  display: flex;
  pad: 10px;
  position: absolute;
  top: -30px;
  left: 49%;
  border-radius: 50% !important;
  height: 60px;
  background-color: #03111e !important;
  border: 1px solid #1f262f !important;
}
.scrollTop-btn:hover {
  background-color: #00c4f4 !important;
}
.arrow-icon {
  color: white !important;
  height: 40px !important;
  width: 40px !important;
}
.footer-links {
  width: 15px;
  height: 15px;
  padding: 12px;
  display: flex;
  align-items: center;
}
.link-style {
  border: 1px solid #00c4f4;
  border-radius: 50%;
}
.link-style:hover {
  background-color: #00c4f4 !important;
}
.footer-title {
  color: white;
  margin: 0px !important;
}
.links-list {
  margin: 10% 0px;
  display: block !important;
}
.links-list a {
  display: block !important;
  color: #a4b4c3;
  text-decoration: none;
  margin-top: 7%;
}
.links-list a:hover {
  color: white;
}
.footer-inputfield {
  font-family: arial !important;
  color: white !important;
  background-color: transparent;
  width: 95% !important;
  height: 50px;
  margin-top: 2%;
  padding-left: 5%;
  border-radius: 10px !important;
  border: 1px solid white;
}
::placeholder {
  color: rgb(185, 174, 174);
  opacity: 1;
}
.plan_button {
  width: 100% !important;
  height: 50px;
  color: white !important;
  text-transform: capitalize !important;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  margin-top: 20px !important;
  font-size: 20px !important;
  text-decoration: bold;
  background: #00c4f4 !important;
}

.submit-icon {
  position: absolute !important;
  right: -10px;
  top: 7px;
}
.submit-icon img {
  width: 40px;
}
.payment-box-bg {
  background-image: linear-gradient(
    159deg,
    #efefef 40%,
    #01111e 40%,
    #01111e 50%,
    #efefef 50%,
    #efefef 90%,
    #01111e 90%,
    #01111e 10%
  );
  background-size: 1097.61px 1067.79px;
}
.payment-card {
  background-color: white;
  border-radius: 10px;
  padding: 5%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 2px 5px -3px;
}

.payment-title {
  color: #03111e;
  font-size: 30px;
  font-weight: 700;
  margin-top: 0px;
  text-align: center;
}
.data-card {
  margin: 5%;
  /* padding: 5%; */
}

.customize-input2 {
  font-family: arial !important;
  color: #03111e !important;
  background-color: transparent;
  width: 30% !important;
  height: 50px;
  margin-top: 2%;
  padding-left: 2%;
  border-radius: 20px !important;
  border: 1px solid;
}
::placeholder {
  color: rgb(59, 54, 54);
  opacity: 1;
}

/* hide arrows from number input */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:focus-visible {
  outline: 1px solid !important;
}
.css-1enotpu-MuiTableRow-root:nth-of-type(odd) {
  background-color: white !important  ;
}
.sidebar-list {
  color: #03111e;
  text-decoration: none;
  display: flex;
  gap: 10px;
}

.industry-dropdown {
  padding: 70px 0 30px 0 !important;
}

.simple-value-data-head {
  display: flex;
  background-color: #0c0f7c;
  justify-content: space-between;
  align-items: center;
  padding: 0 2%;
  margin-bottom: 50px;
  border-radius: 2px;
}

.simple-valuation-period {
  margin-bottom: 50px !important;
}

.financial-data-head {
  display: flex;
  background-color: #0c0f7c;
  justify-content: space-between;
  align-items: center;
  padding: 0 2%;
  border-radius: 2px;
}
.submit-btn {
  width: 150px !important;
  background-color: #0c0f7c !important;
  color: white !important;
}
.findNaicsBtn {
  color: #1976d2;
  font-size: 15px;
  margin-top: 8px;
  border: none;
  cursor: pointer;
  text-decoration: underline;
}
.custom-autocomplete:focus-visible {
  border: none !important;
}

@media screen and (max-width: 480px) {
  .main-title1 {
    margin-top: 30%;
    font-size: 32px;
  }
  .main-title2 {
    font-size: 30px;
  }
  .main-title3 {
    font-size: 16px;
    z-index: 99;
  }
  h1 {
    font-size: 25px !important;
  }
  h2 {
    font-size: 18px !important;
  }
  #canvas-container {
    height: auto;
    width: auto;
  }
}
#canvas-container {
  height: 60vh;
  width: 60vw;
  position: relative;
}

.customize-input3 {
  font-family: arial !important;
  color: #03111e !important;
  background-color: transparent;
  width: 29% !important;
  height: 50px;
  padding-left: 3%;
  border-radius: 20px !important;
  border: 1px solid;
}
::placeholder {
  color: rgb(59, 54, 54);
  opacity: 1;
}

.customize-input4 {
  font-family: arial !important;
  color: #03111e !important;
  background-color: transparent;
  width: 250px !important;
  height: 50px;
  padding-left: 5%;
  border-radius: 20px !important;
  border: 1px solid;
}
::placeholder {
  color: rgb(59, 54, 54);
  opacity: 1;
}
.search-icon-position {
  position: absolute;
  height: 25px;
  top: 28%;
  right: 0px;
}

/* hide arrows from number input */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:focus-visible {
  outline: 1px solid !important;
}

.milestone-form {
  width: 100% !important;
  border: none !important;
  margin-top: 2% !important;
}

.currencies-form {
  width: 45% !important;
  border: none !important;
  margin-top: 2% !important;
}
.industry-form {
  width: 100% !important;
  border: none !important;
  margin-top: 2% !important;
}
.prefix-position1 {
  position: absolute;
  left: 1%;
  top: 15px;
  font-weight: 600;
  font-size: 17px;
}
.prefix-position2 {
  position: absolute;
  left: 35%;
  top: 15px;
  font-weight: 600;
  font-size: 17px;
}
.prefix-position3 {
  position: absolute;
  right: 30%;
  top: 15px;
  font-weight: 600;
  font-size: 17px;
}
.prefix-position4 {
  position: absolute;
  left: 1%;
  top: 15px;
  font-weight: 600;
  font-size: 17px;
}
.prefix-position5 {
  position: absolute;
  right: 36%;
  top: 15px;
  font-weight: 600;
  font-size: 17px;
}
.prefix-position6 {
  position: absolute;
  right: 2%;
  top: 15px;
  font-weight: 600;
  font-size: 17px;
}

.full-width {
  width: 100% !important;
}

#custom-picker .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 22px !important;
  border: 1px solid !important;
}
#custom-picker input:focus-visible {
  outline: none !important;
}

.date-input-box {
  width: 25%;
}
.date-details-input {
  width: 100% !important;
  border: none !important;
  margin-top: 2% !important;
}
.graph-heading {
  background-color: #0c0f7c;
  padding: 1px;
  text-align: center;
  color: white;
  line-height: 0px;
  font-size: 30px;
  margin: 0px;
  /* text-decoration: underline; */
  font-weight: 700;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.cal-area {
  background-color: white;
  padding: 25px;
  width: 250px;
  color: black;
  border-radius: 7px;
  border: 1px solid #e7e7e7;
  display: flex;
  justify-content: space-between;
  /* margin-top: 10%; */
  font-size: 15px;
  align-items: center;
  height: 67px;
}
.toggle-primary-btn {
  background-color: #0c0f7c !important;
  height: 25px !important;
  color: white !important;
  width: 120px !important;
}

.periodLabel {
  /* text-decoration: underline !important; */
  text-transform: capitalize;
  /* border: 5px dotted #0c0f7c !important; */
  color: #0c0f7c !important;
  /* padding: 15px; */
}

.css-qiwgdb-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input
  .adjust-btn-size {
  display: none !important;
}

.button-visible {
  opacity: 1;
  visibility: visible;
  transition: opacity 5s ease-in-out, visibility 0s linear; /* No delay when becoming visible */
}

.toggle-primary-btn-selected {
  background-color: white !important;
  border: 3px solid #0c0f7c !important;
  height: 25px !important;
  font-size: 13px !important;
  color: #0c0f7c !important;
  width: 120px !important;
}

.simple-form-input {
  font-family: arial !important;
  color: #03111e !important;
  background-color: transparent;
  width: 100% !important;
  height: 50px;
  padding-left: 10px;
  border-radius: 20px !important;
  border: 3px solid black;
}

.simple-form {
  font-family: arial !important;
  color: #03111e !important;
  background-color: transparent;
  width: 100% !important;
  height: 50px;
  padding-left: 10px;
  border-radius: 20px !important;
  border: 1px solid gray;
}

.customize-input5 {
  font-family: arial !important;
  color: #03111e !important;
  background-color: transparent;
  width: 29% !important;
  height: 50px;
  padding-left: 3%;
  border-radius: 20px !important;
  border: 3px solid black;
}
.customize-input6 {
  font-family: arial !important;
  color: #03111e !important;
  background-color: transparent;
  width: 29% !important;
  height: 50px;
  padding-left: 3%;
  border-radius: 20px !important;
  border: 1px solid gray;
  width: 80% !important;
}

.adjust-btn-size button {
  min-width: 40px !important;
}

.select_heading {
  padding-left: 15px;
  padding-top: 10px;
}

.scenario-btn {
  background-color: #201b5b !important;
  color: white !important;
  width: 150px !important;
  height: 48px !important;
  border-radius: 100px !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
}

.disabled-scenario-btn {
  background-color: #828285 !important;
  color: #c2c2c2 !important;
  width: 150px !important;
  height: 48px !important;
  border-radius: 100px !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
}
