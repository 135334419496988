.home_my_companies {
  color: #121212 !important;
  font-size: 28px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  display: flex;
  align-items: center;
  gap: 5px;
}

.pdf_heading {
  color: #121212 !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

.financial_doc_heading {
  color: #121212 !important;
  font-size: 28px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
}

.home_main {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.financial_data_sec_heading {
  color: #121212 !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  margin-left: 15px !important;
}
.filter_button {
  background-color: #201b5b !important;
  color: white !important;
  /* width: 100px !important; */
  /* height: 48px !important; */
  border-radius: 100px !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
  padding: 0 20px !important;
  margin-left: 10px !important;
}

.filter_icon {
  font-size: 30px;
  /* padding-right: 10px; */
}

.home_filter {
  background-color: #f2f2f5 !important;
  color: #736060 !important;
  width: auto !important;
  height: 48px !important;
  border-radius: 12px !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
}

.home_add_company {
  background-color: #201b5b !important;
  color: white !important;
  width: auto !important;
  height: 48px !important;
  border-radius: 12px !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
  padding-left: 50px !important;
  padding-right: 50px !important;
  padding-bottom: 20px !important;
  padding-top: 20px !important;
}

.smpleValTxt {
  font-size: 13px !important;
  padding-top: 4px !important;
  padding-bottom: 10px;
}

.disabled_smple_val_txt {
  font-size: 18px !important;
  padding-top: 4px !important;
  padding-bottom: 4px;
  color: #c2c2c2 !important;
}

.signs {
  font-size: auto !important;
  margin-right: 0px !important;
}

.disabled_signs {
  font-size: 30px !important;
  margin-right: 20px !important;
  color: #c2c2c2 !important;
}

.disabled_home_add_company {
  background-color: #828285 !important;
  color: #c2c2c2 !important;
  width: 189px !important;
  height: 48px !important;
  border-radius: 100px !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
}

.update_demo_company_btn {
  background-color: #201b5b !important;
  color: white !important;
  width: 130px !important;
  height: 34px !important;
  border-radius: 29px !important;
  font-size: 12px !important;
  text-transform: capitalize !important;
  margin-left: 20px !important;
}

.filter_btn {
  padding: 0px 20px !important;
  height: 40px !important;
  background-color: #f2f2f5 !important;
  border-radius: 100px !important;
  color: #606060 !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  text-transform: capitalize !important;
}
.icons_quickbooks_data {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
}
.qboConnect {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-top: 2px;
}
.qboConnected {
  background-color: rgb(77, 153, 19) !important;
  color: white !important;
  width: 210px !important;
  height: 48px !important;
  border-radius: 12px !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
  margin-top: 15px !important;
}
