.hidden_cash_subTitle {
  color: #121212 !important;
  font-size: 28px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  display: flex;
  align-items: center;
  gap: 5px;
}
.hidden_cash_subTitle2 {
  /* color: #121212 !important; */
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}
.hidden_cash_note_box {
  background-color: #f2f2f5 !important;
  width: 100% !important;
  padding: 20px 20px 20px 40px !important;
  border-radius: 8px !important;
}
.Comparative_Ratios {
  color: #121212 !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
.accordian_notes {
  background-color: #f2f2f5 !important;
  padding: 0px 10px 0px 30px !important;
}
