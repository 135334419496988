.card_wrapper_main {
  padding: 28px 25px !important;
  border-radius: 12px !important;
  background-color: white !important;
}

.contact_us_wrapper {
  padding: 28px 25px !important;
  border-radius: 12px !important;
  background-color: white !important;
}
