* {
  margin: 0;
  padding: 0;
  /* font-family: Inter !important; */
  font-family: arial !important;
}
body {
  background-color: #f2f2f5;
}
body::-webkit-scrollbar {
  display: none !important;
}
.d-flex {
  display: flex !important;
}
.ai-center {
  align-items: center !important;
}

.hide_sideBar::-webkit-scrollbar {
  display: none !important;
}

.infor_icon {
  color: #00bfff;
  cursor: pointer;
}

.naics_info {
  color: purple;
  width: auto;
  font-size: 10px;
  cursor: pointer;
  padding-right: 7px;
}

.mobile_message {
  background-color: #20195c !important;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  height: fit-content;
  text-align: center;
  padding: 16px;
  width: fit-content;
  color: white !important;
  padding-top: 13vh;
}
