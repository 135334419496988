.financial_data_heading {
  color: #121212 !important;
  font-size: 32px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
}
.financial_data_right_heading {
  margin-top: 5px !important;
  color: #606060 !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.back_to_top_btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  background-color: #bebec7;
  border-radius: 5px;
  padding: 10px;
  font-size: 30px !important;
  cursor: pointer;
}

.subtitle_financial_data {
  color: #414141 !important;
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  /* margin-bottom: 12px !important; */
  font-style: italic !important;
}
.tabs_content_heading {
  color: #414141 !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  margin-bottom: 12px !important;
}

.tabs_content_input_box_loc {
  width: 100% !important;
  height: 70px;
  display: flex !important;
  align-items: center !important;
  border-radius: 12px !important;
  background-color: #f3f4f4;
  /* border: 1px solid #b6b6b6 !important; */
  overflow: hidden !important;
}

.Headings_fastmoney {
  font-weight: 650 !important;
  color: rgb(71, 68, 68);
}

.line_of_credit_inputs2 {
  width: 85% !important;
  height: 100% !important;
  /* text-align: center !important; */
  background-color: #f3f4f4 !important;
  border: none !important;
  /* border-color: rgb(158, 155, 155) !important; */
  outline: none !important;
  padding-left: 7px;
}

.line_of_credit_inputs2:focus {
  border: none !important;
  outline: none !important;
  /* background-color: white !important; */
  background-color: #f3f4f4 !important;
}

.line_of_credit_inputs2:-webkit-autofill {
  background-color: #f3f4f4 !important;
}

/* .line_of_credit_inputs2:-moz-autofill {
  background-color: #your-desired-color !important;
  color: #your-desired-text-color !important;
}

.line_of_credit_inputs2:-ms-autofill {
  background-color: #your-desired-color !important;
  color: #your-desired-text-color !important;
} */

.dollarSign {
  display: flex;
  justify-content: center;
  align-items: center;
  color: gray;
  width: 15%;
  height: 100%;
  font-weight: 700;
}
.dollarSign2 {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #47ceb2;
  width: 15%;
  height: 100%;
  font-weight: 700;
}

.icons_financial_data {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 60%;
  padding-top: 20px !important;
}

.tabs_content_input_box {
  width: 100% !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 100px !important;
  border: 1px solid #b6b6b6 !important;
  overflow: hidden !important;
}

.curr_input_box {
  display: flex !important;
}

.adjust-btn-size button {
  min-width: 40px !important;
}
.adjust_period_menu_icons {
  display: "flex" !important;
  justify-content: space-between !important;
}
.tabs_content_percent {
  width: 20% !important;
  background-color: #00bdff !important;
  height: 56px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: white !important;
  font-size: 16px !important;
  border-top-right-radius: 100px !important;
  border-bottom-right-radius: 100px !important;
}

.tabs_content_dollar_loc {
  width: 20% !important;
  background-color: #00bdff !important;
  height: 35px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: white !important;
  font-size: 16px !important;
  border-top-left-radius: 100px !important;
  border-bottom-left-radius: 100px !important;
}

.tabs_content_dollar {
  width: 20% !important;
  background-color: #00bdff !important;
  height: 56px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: white !important;
  font-size: 16px !important;
  border-top-left-radius: 100px !important;
  border-bottom-left-radius: 100px !important;
}
.tabs_content_input {
  font-size: 16px !important;
  width: 100% !important;
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  height: 56px !important;
  padding: 0px 20px !important;
}

.need_input {
  width: 100%;
  height: 65px;
  background-color: #e6e6e6;
  padding: 10px;
  border: none;
  font-size: 16px !important;
}
.curr_prefix {
  width: 4% !important;
  background-color: #e6e6e6 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: black !important;
  height: 65px;
  padding: 10px 10px;
  border-right: 1px solid #bcbcbc;
}

.prior_curr_prefix {
  width: 4% !important;
  background-color: #e6e6e6 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: black !important;
  height: 56px !important;
  padding: 0 30px;
  border-right: 1px solid #bcbcbc;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.prior_saving_input {
  font-size: 16px !important;
  width: 100% !important;
  background-color: #e6e6e6 !important;
  border: none !important;
  outline: none !important;
  height: 56px !important;
  padding: 0px 20px !important;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  text-align: right;
  max-width: 187px !important;
}

.saved {
  font-size: 16px !important;
  width: 100% !important;
  background-color: #808080 !important;
  border: none !important;
  color: white;
  outline: none !important;
  max-width: 187px !important;
  height: 56px !important;
  padding: 0px 20px !important;
  border-radius: 10px;
  text-align: right;
}

.saved {
  font-size: 16px !important;
  width: 100% !important;
  background-color: #808080 !important;
  border: none !important;
  color: white;
  outline: none !important;
  max-width: 187px !important;
  height: 56px !important;
  padding: 0px 20px !important;
  border-radius: 10px;
  text-align: right;
}

.pie-chart-container {
  width: 300px; /* Adjust as needed */
  height: 300px; /* Adjust as needed */
}

.need_to_save {
  font-size: 16px !important;
  width: 100% !important;
  background-color: deepskyblue !important;
  border: none !important;
  color: white;
  outline: none !important;
  max-width: 187px !important;
  height: 56px !important;
  padding: 0px 20px !important;
  border-radius: 10px;
  text-align: right;
}

.disabled_tabs_content_input::placeholder {
  color: rgb(158, 155, 155) !important;
}

.disabled_tabs_content_input {
  font-size: 16px !important;
  width: 100% !important;
  background-color: transparent !important;
  border: none !important;
  border-color: rgb(158, 155, 155) !important;
  outline: none !important;
  height: 56px !important;
  padding: 0px 20px !important;
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  border-radius: 20px !important;
}
.highlighted-red {
  background-color: #ffe6e6 !important; 
  border-radius:0 30px 30px 0;
}
.highlighted-red-border {
  background-color: #ffe6e6 !important; 
  border-radius:30px;
}
.lightblue-highlight {
  background-color:rgb(232,240,254)!important; 
  border-radius:0 30px 30px 0;
}
.lightblue-highlight-border {
  border: 1px solid #00c0ff !important;
  background-color: rgb(232,240,254)!important; 
  border-radius:30px;
}
