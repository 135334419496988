.simple_valuation_inputs {
  width: 100% !important;
  height: 56px !important;
  border: 1px solid #b6b6b6 !important;
  border-radius: 100px !important;
  outline: none !important;
  font-size: 18px !important;
}

.disabled_simple_valuation_graph_inputs {
  width: 200px !important;
  height: 35px !important;
  padding: 0px 5px !important;
  outline: none !important;
  border-radius: 5px !important;
  border: 1px solid rgb(180, 172, 172) !important;
  font-size: 16px !important;
}

.simple_valuation_graph_inputs {
  width: 200px !important;
  height: 35px !important;
  padding: 0px 5px !important;
  outline: none !important;
  border-radius: 5px !important;
  border: 1px solid gray !important;
  font-size: 16px !important;
}
.simple_valuation_graph_inputs_bold {
  width: 200px !important;
  height: 35px !important;
  padding: 0px 5px !important;
  outline: none !important;
  border-radius: 5px !important;
  border: 3px solid gray !important;
  font-size: 16px !important;
}
