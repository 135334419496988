.header_main {
  background-color: white !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 16px 30px !important;
  border-left: 2px solid #f2f2f5;
}
.HeaderProfile {
  width: 48px !important;
  height: 48px !important;
}
.header_profile_box {
  display: flex !important;
  align-items: center !important;
}
.header_profile_typo {
  margin-left: 8px !important;
}
.header_user_name {
  color: #121212 !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
.header_user_mail {
  color: #606060 !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
.header_search_bar {
  border-radius: 50px !important;
  background: #f2f2f5 !important;
  padding: 0px 24px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  box-shadow: 0px 3px 4px 0px lightgray !important;
}

.css-1bp1ao6 {
  border: none !important;
}

.header_search_input {
  border: none !important;
  outline: none !important;
  background-color: transparent !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
.header_autoComplete {
  width: 370px !important;
  border: none !important;
  outline: n one !important;
}

.header_autoComplete input:focus-visible {
  outline: none !important;
}

.header_search_bar2 {
  height: 50px;
  border-radius: 50px !important;
  background: #f2f2f5 !important;
  padding: 0px 24px !important;
  display: flex !important;
  margin-top: 20px !important;
  align-items: center !important;
  box-shadow: 0px 3px 4px 0px lightgray !important;
  padding-top: 10px;
}

.popover_input {
  border: none;
  outline: none;
  background: none;
  box-shadow: none;
  padding: 0;
  margin: 0;
  font: inherit;
  color: inherit;
  opacity: 50%;
  width: 100%;
}

.popover_input:focus {
  /* Remove focus styling */
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
  opacity: 100%;
}
